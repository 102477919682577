import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AminHeratiLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Herati"
    institution="Johns Hopkins"
    referralCode="DRHERATI"
    physicianURL="https://www.hopkinsmedicine.org/profiles/details/amin-herati"
  />
)

export default AminHeratiLandingPage
